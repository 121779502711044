<template>
  <div class="header">
    <img class="ozon" src="../../assets/img/ozon.png" />
    <div class="header-container">
      <div class="menu-container">
        <img src="../../assets/img/xway.svg" />
        <font-awesome-icon class="menu-icon" :icon="!showMenu ? ['fas', 'bars'] : ['fas', 'xmark']" style="color: #ffffff" @click="toggleMenu" />
        <ul v-if="showMenu" class="mini-menu">
          <li class="sub_mini-menu-item" @click="wantToSell">Хочу продавать</li>
          <li class="sub_mini-menu-item" @click="wantToBuy">Хочу купить</li>
          <li class="sub_mini-menu-item no-active">Другие продукты XWAY:</li>
          <li class="sub_mini-menu-item" @click="showADV">Автоматизация рекламы XWAY ADV</li>
          <li class="sub_mini-menu-item" @click="showPromotion">Продвижение на маркетплейсах</li>
          <li class="sub_mini-menu-item" @click="showDashboards">Аналитическая отчетность</li>
        </ul>
        <ul class="menu">
          <li @click="wantToSell">Хочу продавать</li>
          <li @click="wantToBuy">Хочу купить</li>
          <li>
            Другие продукты XWAY
            <ul class="sub_menu">
              <li class="sub_menu-item" @click="showADV">Автоматизация рекламы XWAY ADV</li>
              <li class="sub_menu-item" @click="showPromotion">Продвижение на маркетплейсах</li>
              <li class="sub_menu-item" @click="showDashboards">Аналитическая отчетность</li>
            </ul>
          </li>
        </ul>
      </div>

      <div class="card-container">
        <div class="title-card">
          <span class="title">БИРЖА КАРТОЧЕК ТОВАРА НА OZON</span>
          <div class="button-container">
            <button type="button" class="btn green" @click="wantToSell">ДОБАВИТЬ КАРТОЧКУ НА БИРЖУ</button>
            <button type="button" class="btn white" @click="showPromotion">ПРОКАЧАТЬ СВОЮ КАРТОЧКУ</button>
          </div>
        </div>

        <div class="content-card">
          <div class="count-card">
            <div class="count-card-block">
              <span class="count">{{formatPrice(countCards, {minimumFractionDigits : 0})}}</span>
              <span class="text">карточек на бирже</span>
            </div>
            <div class="count-card-block">
              <span class="count">{{formatPrice(min, {minimumFractionDigits : 0})}}</span>
              <span class="text">минимальная</span>
              <span class="text">цена карточки, руб</span>
            </div>

          </div>

          <div class="count-card">
            <div class="count-card-block">
              <span class="count">{{countCategories}}</span>
              <span class="text">категории карточек</span>
            </div>
            <div class="count-card-block">
              <span class="count">{{formatPrice(max, {minimumFractionDigits : 0})}}</span>
              <span class="text">максимальная</span>
              <span class="text">цена карточки, руб</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import formatPrice from '../../utils/price-formatter'

  export default {
    props: {
      countCards: {
        type: Number,
        default: 0
      },
      countCategories: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: 0
      },
      min: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        formatPrice,
        showMenu: false
      }
    },
    created () {
      window.addEventListener('resize', this.updateShowMenu)
    },
    methods: {
      updateShowMenu() {
        const widthWindow = window.innerWidth;
        if (widthWindow > 768) {
          this.showMenu = false;
        }
      },
      toggleMenu() {
        this.showMenu = !this.showMenu;
      },
      wantToSell() {
        if (this.showMenu) {
          this.showMenu = false;
        }
        window.location.href = 'https://xway.ru/cardexchange/seller/';
      },
      wantToBuy() {
        if (this.showMenu) {
          this.showMenu = false;
        }
        window.location.href = 'https://xway.ru/cardexchange/buyer/ ';
      },
      showADV() {
        if (this.showMenu) {
          this.showMenu = false;
        }
        window.location.href = 'https://xway.ru/adv/';
      },
      showPromotion() {
        if (this.showMenu) {
          this.showMenu = false;
        }
        window.location.href = 'https://xway.ru/promotion/';
      },
      showDashboards() {
        if (this.showMenu) {
          this.showMenu = false;
        }
        window.location.href = 'https://xway.ru/dashboards/';
      }
    }
  }
</script>
<style scoped>
.header {
  /* height: 420px; */
  width: 100%;
  background: radial-gradient(97.86% 97.86% at 59.69% 131.43%, #0066FF 0%, #08162C 100%);
  overflow: hidden;
  position: relative;
}

.header > div {
  padding: 20px 60px;
}

.menu .sub_menu {
  flex-direction: column;
  position: absolute;
  background-color: #ffffff;
  padding: 0;
}

.sub_menu-item {
  color: #3c3c47;
  font-size: 14px;
  padding: 10px;
  margin: 0;
}

.sub_menu-item:hover {
  background-color: #A3A3AC;
}

ul ul{
  display: none;
}

li:hover ul{
  display: flex;
}

.ozon {
  height: 100%;
  position: absolute;
  top: 0px;
  left: -50px;
}

.header-container {
  display: flex;
  flex-direction: column;
}

.card-container {
  display: flex;
  justify-content: space-between;
  gap: 60px;
  z-index: 10;
}

.content-card {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 30px;
  margin-bottom: 50px;
}

.button-container {
  display: flex;
  gap: 20px;
  z-index: 100;
  margin-top: 36px;
}

.title-card {
  padding-top: 50px;
  width: 530px;
}

.title {
  font-size: 44px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 36px;
}

.count-card {
  margin-top: 14px;
}

.count-card .count-card-block {
  margin-top: 14px;
  text-align: center;
}

.count-card span {
  display: block;
  font-size: 16px;
  color: #fff;
}

.count-card span.count {
  font-size: 70px;
  font-weight: 700;
  color: #00f6ca;
}

.count-card span.text {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

@supports (-webkit-text-stroke: 1px #00f6ca) {
  .count-card span.count {
    color: transparent;
    -webkit-text-stroke: 4px #00f6ca;
  }
}

.menu-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
  z-index: 100;
}

ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 20px;
  color: #fff;
  font-weight: 400;
  font-size: 16px;
}

ul li:hover {
  cursor: pointer;
}

.menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .ozon {
    object-fit: cover;
  }
  .header > div {
    padding: 10px 16px;
  }
  .title-card {
    padding-top: 0;
    text-align: center;
    width: 100%;
  }
  .menu {
    display: none;
  }
  .menu-icon {
    display: block;
  }
  .title {
    font-size: 34px;
    color: #fff;
    margin-bottom: 14px;
  }
  .content-card {
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 40px;
  }
  .count-card {
    margin-top: 0;
  }
  .card-container {
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
  }
  .menu-container {
    margin-bottom: 34px;
  }
  .button-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  .count-card span.count {
    font-size: 54px;
  }
  .mini-menu {
    position: absolute;
    width: 100%;
    z-index: 1000000;
    background-color: #000000;
    color: #ffffff;
    opacity: 93%;
    top: 40px;
    left: 1px;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sub_mini-menu-item {
    padding: 12px;
    margin: 0;
    font-weight: 700;
    width: 100%;
    text-align: center;
  }

  .sub_mini-menu-item.no-active {
    color: #FFFFFFB2;
    font-weight: 400;
    padding: 20px 0 10px 0;
  }

  .sub_mini-menu-item.no-active:hover {
    background-color: #000000;
    color: #ffffff;
  }

  .sub_mini-menu-item:hover {
    background-color: #5b40ff;
    opacity: 93%;
  }
}
</style>
