<template>
  <div class="footer">
    <div class="title">XWAY ПОМОЖЕТ ВАМ ПРОДАТЬ КАРТОЧКИ НА OZON</div>
    <div class="footer-cards">
      <div class="card">
        <img src="../../assets/icons/icon_buyer.png" />
        <span>Поиск покупателей на вашу карточку</span>
      </div>
      <div class="card">
        <img src="../../assets/icons/icon_price.png" />
        <span>Поможем обосновать рекомендованную цену</span>
      </div>
      <div class="card">
        <img src="../../assets/icons/icon_intelect.png" />
        <span>Помощь в передаче интеллектуальных прав</span>
      </div>
      <div class="card">
        <img src="../../assets/icons/icon_safe.png" />
        <span>Безопасная сделка</span>
      </div>
      <div class="card">
        <img src="../../assets/icons/icon_disput.png" />
        <span>Справедливый арбитраж</span>
      </div>
    </div>
    <div>
      <button type="button" class="btn green biggest">ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ</button>
    </div>
  </div>
</template>
<style>
.footer {
  background-color: #f0eefe;

  padding: 100px;
  text-align: left;
}

.footer .title {
  font-size: 24px;
  font-weight: 700;
  width: 400px;
}

.biggest {
  font-size: 14px!important;
  padding: 12px 24px!important;
}

@media (max-width: 768px) {
  .footer {
    padding: 15px;
    width: calc(100% - 30px);
    overflow: hidden;
  }

  .footer .title {
    width: 100%;
    margin-top: 10px;
  }

  .footer-cards {
    overflow: auto;
  }
}

.footer .footer-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: row dense;
  grid-column-gap: 20px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.footer .footer-cards .card {
  background: linear-gradient(to right bottom, #fff, #f0eefe);
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.footer .footer-cards .card img {
  height: 60px;
  width: 100%;
  object-fit: contain;
  display: block;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}
</style>
