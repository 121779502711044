<template>
    <vue-final-modal v-model="localShowModal" name="send-request-modal" classes="modal-container" content-class="modal-content">
        <span class="modal__title">Отправить запрос</span>
        <div class="modal__content">
            <p>{{text}}</p>
        </div>
        <div class="modal-form">
            <label class="input">
                <input
                  type="text"
                  v-model="name"
                  :class="name ? 'white' : ''"
                >
                <span><span>Имя</span></span>
            </label>

            <label class="input">
                <input
                  type="email"
                  v-model="email"
                  :class="email ? 'white' : ''"
                >
                <span><span>Email</span></span>
            </label>

            <label class="input">
                <input
                  type="tel"
                  v-model="tel"
                  :class="tel ? 'white' : ''"
                >
                <span><span>Телефон</span></span>
            </label>

            <label v-if="addingText" class="input">
                <textarea
                  placeholder="Ссылки на карточки, которые вы хотите оценить"
                  v-model="urls"
                  :class="urls ? 'white' : ''"
                />
            </label>
        </div>
        <div class="modal__action">
            <button type="button" class="btn green" @click="sendForm">ОТПРАВИТЬ</button>
        </div>
        <div class="modal__content">
            <p>{{addingText}}</p>
        </div>
    </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
export default {
  name: "SendRequestModal",
  components: {VueFinalModal},
  props: {
      showModal: {
          type: Boolean,
          default: false
      },
      text: {
          type: String,
          default: ''
      },
      addingText: {
          type: String,
          default: ''
      }
  },
  computed: {
      localShowModal() {
          return this.showModal;
      }
  },
  data() {
    return {
      name: '',
      email: '',
      tel: '',
      urls: '',
    }
  },
  methods: {
    sendForm() {
      const formData = {
        name: this.name,
        email: this.email,
        tel: this.tel,
        urls: this.urls,
      }
      this.$emit('send', formData);
      this.name = '';
      this.email = '';
      this.tel = '';
      this.urls = '';
    }
  }
}
</script>

<style scoped>
    ::v-deep .modal-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ::v-deep .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 35%;
        max-height: 90%;
        margin: 0 1rem;
        padding: 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.25rem;
        background: #fff;
    }
    @media (max-width: 768px) {
      ::v-deep .modal-content {
        width: 75%;
      }
      .modal-form label.input {
        margin: 20px 0 0 0;
      }
    }

    .modal__title {
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 30px;
    }
    .modal__content {
        flex-grow: 1;
        overflow-y: auto;
    }
    .modal__content p {
        text-align: center;
    }
    .modal__action {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 30px;
    }
    @media (max-width: 768px) {
        ::v-deep .modal-content {
            width: 75%;
        }
        .modal-form label.input {
            margin: 20px 0 0 0;
        }
        .modal-form label.input:first-child {
            margin-top: 5px;
        }
        .modal__action {
            margin-top: 0;
        }
    }
    .modal__action>*:first-child {
      flex: 1;
    }
    .modal-form {
        float: left;
        width: 100%;
        margin: 0 0 50px 0;
    }
    .modal-form label.input {
        float: left;
        width: 100%;
        height: 42px;
        margin: 50px 0 0 0;
        position: relative;
        clear: both;
    }
    .modal-form label.input span {
        width: 100%;
        height: 40px;
        line-height: 40px;
        position: absolute;
        left: 0;
        cursor: text;
    }
    .modal-form label.input span span {
        position: absolute;
        top: 0;
        z-index: 1;
        font-size: 16px;
        color: #A3A3AC;
        text-indent: 10px;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
    }
    .modal-form label.input span:before {
        content: '';
        width: 0%;
        height: 0;
        background-color: #A3A3AC;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 99;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
    }
    .modal-form label.input span:after {
        content: '';
        width: 0%;
        height: 0;
        background-color: #A3A3AC;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 99;
        transition: 0.3s;
        -webkit-transition: 0.3s;
        -moz-transition: 0.3s;
    }
    .modal-form textarea,
    .modal-form input {
        float: left;
        width: calc(100% - 20px);
        height: 40px;
        padding: 0 10px;
        border: 0;
        border-bottom: 1px solid #A3A3AC;
        background-color: transparent;
        color: #2c3e50;
        font-size: 16px;
        position: relative;
        z-index: 99;
    }
    .modal-form textarea::placeholder {
        color: #A3A3AC;
        font-size: 16px;
        font-family: 'Gotham Pro';
    }
    .modal-form textarea:focus,
    .modal-form input:focus {
        outline: 0;
    }
    .modal-form textarea.white,
    .modal-form input.white {
        background-color: #ffffff;
    }

    .modal-form textarea:focus + span span
    .modal-form input:focus + span span {
        cursor: initial;
        position: absolute;
        top: -35px;
        color: #A3A3AC;
    }
    .modal-form textarea:focus + span:before
    .modal-form input:focus + span:before {
        width: 50%;
    }
    .modal-form textarea:focus + span:after
    .modal-form input:focus + span:after {
        width: 50%;
    }
    .modal-form span.fixed span {
        top: -35px;
    }
</style>
