<template>
    <vue-final-modal v-model="localShowModal" name="success-modal" classes="modal-container" content-class="modal-content">
        <img width="72" height="72" src="../../assets/icons/icon_success.png" />
        <span class="modal__title">Спасибо</span>
        <div class="modal__content">
            <p>Наш менеджер скоро свяжется с вами</p>
        </div>
        <div class="modal__action">
            <button type="button" class="btn green" @click="closeModal">ХОРОШО</button>
        </div>
    </vue-final-modal>
</template>

<script>
import { VueFinalModal } from 'vue-final-modal'
export default {
    name: "SuccessModal",
    components: {VueFinalModal},
    props: {
        showModal: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        localShowModal() {
            return this.showModal;
        }
    },
    methods: {
      closeModal() {
          this.$emit('close');
      }
    }
}
</script>

<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
::v-deep .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}
@media (max-width: 768px) {
  ::v-deep .modal-content {
    width: 75%;
  }
}
.modal__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-top: 30px;
}
.modal__content {
    flex-grow: 1;
    overflow-y: auto;
}
.modal__action {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 30px;
}
.modal__action>*:first-child {
    flex: 1;
}
</style>