<template>
  <div class="filters-column">
    <div class="filter-header">
      <h3>Фильтры</h3>
      <font-awesome-icon :icon="['fas', 'xmark']" @click="$emit('toggle-fitler')" />
    </div>
    <div class="form-block">
      <label>Категория</label>
      <Multiselect
        v-model="category"
        :options="categories"
        @clear="clearCategory"
      />
    </div>
    <div class="form-block">
      <label>Цена карточки товара</label>
      <div class="price">
        <input type="number" min="0" placeholder="от" v-model="priceFrom" />
        <input type="number" min="0" placeholder="до" v-model="priceTo" />
      </div>
    </div>

    <div class="form-block">
      <label>Среднее количество продаж в месяц</label>
      <div
        v-for="item in avg_num_of_sales_list"
        :key="item.value"
        class="check-form"
      >
        <input
          class="check-input"
          type="radio"
          name="sales"
          :id="item.value"
          :value="item.value"
          :checked="item.value === avg_num_of_sales"
          @change="changeAvgNumOfSales"
        />
        <label
          :for="item.value"
          class="text"
        >{{item.name}}</label>
      </div>
    </div>

    <div class="form-block" style="display: none">
      <label>Средняя конверсия выкупа в добавление</label>
      <div
        v-for="item in avg_conv_list"
        :key="item.value"
        class="check-form"
      >
        <input
          class="check-input"
          type="radio"
          name="radio"
          :id="item.value"
          :value="item.name"
          :checked="item.name === avg_conv"
          @change="changeAvgConv" />
        <label
          :for="item.value"
          class="text"
        >{{item.name}}</label>
      </div>
    </div>

    <div class="form-block" style="display: none">
      <label>Схема продажи</label>
      <div class="check-form">
        <input class="check-input" type="checkbox" name="FBO" id="FBO" :checked="isCheckedScheme('FBO')" @change="changeScheme" />
        <label for="FBO" class="text">FBO</label>
      </div>
      <div class="check-form">
        <input class="check-input" type="checkbox" name="FBS" id="FBS" :checked="isCheckedScheme('FBS')" @change="changeScheme" />
        <label for="FBS" class="text">FBS</label>
      </div>
    </div>

    <div class="form-block">
      <label>Имя продавца</label>
      <input v-model="sellerName" />
    </div>

    <div class="form-block">
      <label>ID кабинета</label>
      <input v-model="cabinetId" />
    </div>

    <div class="form-block">
      <label>Наименование товара</label>
      <input v-model="productName" />
    </div>

    <div class="form-block">
      <label>Наименование бренда</label>
      <Multiselect
        v-model="brand"
        :options="brands"
        @clear="clearBrand"
      />
    </div>

    <div class="switcher-block">
      <x-switcher :checked="is_available" @change="changeIsAvailable" />
      <span>Сейчас в продаже</span>
    </div>

    <div class="form-block">
      <label>Кол-во отзывов, шт</label>
      <div class="feddback">
        <input type="number" min="0" placeholder="от" v-model="numFeedbackFrom" />
        <input type="number" min="0" placeholder="до" v-model="numFeedbackTo" />
      </div>
    </div>

    <div class="switcher-block">
      <x-switcher :checked="is_contracts" @change="changeIsContracts" />
      <span>Наличие контрактов с производством</span>
    </div>

    <div class="switcher-block">
      <x-switcher :checked="is_amount_discount" @change="changeIsAmountDiscount" />
      <span>Объемная скидка в контракте</span>
    </div>
    <div class="button-block">
      <button typ="button" class="btn violet-transparent" @click="resetFilter">СБРОС ФИЛЬТРА</button>
      <button typ="button" class="btn green" @click="filtering">ФИЛЬТРОВАТЬ</button>
    </div>
  </div>
</template>

<script>
import XSwitcher from "../../XSwitcher";
import Multiselect from '@vueform/multiselect'

export default {
  name: "App",
  components: {
    XSwitcher,
    Multiselect
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    currentCategories: {
      type: Array,
      default: () => []
    },
    currentBrands: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    currentCategories: {
      deep: true,
      immediate: true,
      handler() {
        this.categories = ['Любая категория', ...this.currentCategories];
      },
    },
    currentBrands: {
      deep: true,
      immediate: true,
      handler() {
        this.brands = ['Любой бренд', ...this.currentBrands];
      },
    },
  },
  data() {
    return {
      brand: null,
      category: null,
      avg_num_of_sales: null,
      avg_conv: null,
      is_contracts: null,
      is_amount_discount: null,
      is_available: null,
      numFeedbackFrom: null,
      numFeedbackTo: null,
      priceFrom: null,
      priceTo: null,
      sellerName: null,
      cabinetId: null,
      productName: null,
      categories: [],
      brands: [],
      schemes: [],
      avg_num_of_sales_list: [
        {name: 'Любое количество', value: 'all'},
        {name: 'до 50 продаж', value: '-50'},
        {name: 'От 50 до 300 продаж', value: '50-300'},
        {name: 'От 300 до 1000 продаж', value: '300-1000'},
        {name: 'От 1000 до 5000 продаж', value: '1000-5000'},
        {name: 'Более 5000 продаж', value: '5000-'},
      ],
      avg_conv_list: [
        {name: 'До 1%', value: '1'},
        {name: 'От 1% до 5%', value: '1-5'},
        {name: 'От 5% до 10%', value: '5-10'},
        {name: 'От 10% до 30%', value: '10-30'},
        {name: 'От 30% до 50%', value: '30-50'},
        {name: 'Выше 50%', value: '50'},
      ]
    }
  },
  methods: {
    changeAvgNumOfSales(e) {
      this.avg_num_of_sales = e.target.value === 'all' ? null : e.target.value;
    },
    changeAvgConv(e) {
      this.avg_conv = e.target.value;
    },
    changeIsContracts(is_contracts) {
      if (typeof is_contracts === 'boolean') {
        this.is_contracts = Number(is_contracts);
      }
    },
    changeIsAmountDiscount(is_amount_discount) {
      if (typeof is_amount_discount === 'boolean') {
        this.is_amount_discount = Number(is_amount_discount);
      }
    },
    changeIsAvailable(is_available) {
      if (typeof is_available === 'boolean') {
        this.is_available = is_available ? Number(is_available) : null;
      }
    },
    changeScheme(e) {
      if (e.target.checked) {
        this.schemes.push(e.target.name);
      } else {
        this.schemes = this.schemes.filter(scheme => scheme !== e.target.name)
      }
    },
    isCheckedScheme(val) {
      return this.schemes.includes(val);
    },
    resetFilter() {
      this.brand = null;
      this.category = null;
      this.avg_num_of_sales = null;
      this.avg_conv = null;
      this.is_contracts = null;
      this.is_amount_discount = null;
      this.is_available = null;
      this.numFeedbackFrom = null;
      this.numFeedbackTo = null;
      this.priceFrom = null;
      this.priceTo = null;
      this.sellerName = null;
      this.cabinetId = null;
      this.productName = null;
      this.schemes = [];
      this.$emit('reset');
    },
    clearBrand() {
      this.brand = '';
    },
    clearCategory() {
      this.category = '';
    },
    filtering() {
      const filter = {
        brand: this.brand !== null && this.brand !== 'Любой бренд' ? String(this.brand) : null,
        category: this.category !== null && this.category !== 'Любая категория' ? String(this.category) : null,
        avg_num_of_sales: this.avg_num_of_sales !== null ? this.avg_num_of_sales.split('-') : null,
        is_amount_discount: this.is_amount_discount !== null ? String(this.is_amount_discount) : null,
        avg_conv: this.avg_conv !== null ? String(this.avg_conv) : null,
        is_contracts: this.is_contracts !== null ? String(this.is_contracts) : null,
        is_available: this.is_available !== null ? String(this.is_available) : null,
        sellerName: this.sellerName !== null ? String(this.sellerName) : null,
        cabinetId: this.cabinetId !== null ? String(this.cabinetId) : null,
        title: this.productName !== null ? String(this.productName) : null,
        item_price: this.priceFrom !== null || this.priceTo !== null ? [this.priceFrom, this.priceTo] : null,
        number_of_comments: this.numFeedbackFrom !== null || this.numFeedbackTo !== null ? [this.numFeedbackFrom, this.numFeedbackTo] : null,
        scheme: this.schemes.length ? this.schemes.join(', ') : null
      };
      if (Object.values(filter).every(value => value === null)) {
        this.$emit('reset');
      } else {
        this.$emit('filtering', filter);
      }
    }
  }
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>

<style>
.filters-column {
  text-align: left;
}

.filters-column > p {
  margin-top: 0;
}

.filter-header {
  display: none;
}

@media (max-width: 768px) {
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #E5E5E9;
  }
}

input {
  width: calc(100% - 16px);
  border-radius: 4px;
  border: 1px solid #cacad3;
  padding: 9px 8px;
}
.check-form input,
.check-form label {
  display: inline-block;
  margin-right: 5px;
  font-weight: 400!important;
}

.check-form {
  display: flex;
  align-items: flex-end;
}

.check-input {
  background-color: #1f2937;
  border-color: #1f2937;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  margin-bottom: 5px;
}

.multiselect-option.is-selected,
.multiselect-option.is-selected:hover {
  background-color: #5B40FF;
}

.multiselect.is-active {
  box-shadow: none;
}
</style>

<style scoped>
.form-block {
  margin-top: 20px;
}

.filters-column label {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 5px;
}

.filters-column input:focus {
  outline:none;
  border: 1px solid #5B40FF;
}

.switcher-block {
  display: flex;
  align-items: baseline;
  margin-top: 20px;
}

.button-block {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  gap: 5px;
}

.button-block .btn {
  flex: 1;
}

.switcher-block label {
  margin-bottom: 0;
}

.switcher-block span {
  font-size: 13px;
  font-weight: 600;
  margin-left: 5px;
  align-self: center;
}

.feddback,
.price {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.filters-column .text {
  font-size: 13px;
}

.form-block {
  display: grid;
  grid-template-columns: 1fr;
}
</style>
