export const defaultItemPerPageSelected = () => ({
  name: '10',
  value: 10,
});

export const defaultItemsPerPageOptions = () => [
  10,
  20,
  50,
  100,
  200,
];
