<template>
    <label class="checkbox">
        <input type="checkbox" class="checkbox__input" :checked="checked" @change="(e) => $emit('change', e.target.checked)">
        <div class="checkbox__div" />
    </label>
</template>

<script>
export default {
  name: 'XSwitcher',
  props: {
    checked: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.checkbox__div {
  position: relative;
  width: 40px;
  height: 20px;
  background-color: #cacad3;
  border-radius: 40px;
}

.checkbox__div::before {
  position: absolute;
  top: 3px;
  left: 3px;
  width: 14px;
  height: 14px;
  background-color: #ffffff;
  border-radius: 50%;
  content: '';
}

.checkbox__input {
  display: none;
}

.checkbox__input:checked ~ .checkbox__div {
  background-color: #5b40ff;
}

.checkbox__input:checked ~ .checkbox__div::before {
  left: 23px;
}
</style>
