<template>
  <div class="card">
    <div class="card-info">
      <div class="image-block">
        <img :src="card.img" />
      </div>

      <a href="#">{{card.title}}</a>
      <div class="brand">
        <span class="label">Бренд:</span>
        <span class="text">{{card.brand}}</span>
      </div>
      <div>
        <span class="label">Категория:</span>
        <span class="text">{{card.category}}</span>
      </div>
      <div>
        <span class="label">Цена:</span>
        <span class="text">{{formatPrice(card.item_price, {minimumFractionDigits : 0})}} ₽</span>
      </div>
      <div class="discount">
        <span class="label">Цена со скидкой:</span>
        <span class="text">{{formatPrice(card.item_price_discount, {minimumFractionDigits : 0})}} ₽ <span class="discount-percent">(–{{Math.round((card.item_price - card.item_price_discount)*100/card.item_price)}}%)</span></span>
      </div>
      <div>
        <span class="label">OZON SKU:</span>
        <a
          :href="`https://www.ozon.ru/product/${card.ozon_sku}/?oos_search=false`"
          target="_blank"
          class="text-sku"
        >
          {{card.ozon_sku}}
        </a>
      </div>
      <div>
        <span class="label">Сейчас в продаже:</span>
        <span class="text">{{Number(card.is_available) === 1 ? 'Да' : 'Нет'}}</span>
      </div>
      <div>
        <span class="label">Ср. кол-во продаж в мес.:</span>
        <span class="text">{{card.avg_num_of_sales}}</span>
      </div>
      <div v-if="card.avg_conv">
        <span class="label">Ср. конверсия выкупа в добавление:</span>
        <span class="text">{{card.avg_conv}}</span>
      </div>
      <div v-if="card.scheme">
        <span class="label">Схема продаж:</span>
        <span class="text">{{card.scheme}}</span>
      </div>
      <div>
        <span class="label">Наличие контрактов с производством:</span>
        <span class="text">{{Number(card.is_contracts) === 1 ? 'Да' : 'Нет'}}</span>
      </div>
      <div>
        <span class="label">Объёмная скидка в контракте:</span>
        <span class="text">{{Number(card.is_amount_discount) === 1 ? 'Да' : 'Нет'}}</span>
      </div>

      <div>
        <div class="feedback-comments">
          <div>
            <font-awesome-icon :icon="['fas', 'star']" class="icon" style="color: #FEC02F;" />
            <span class="label">{{card.rating}}</span>
          </div>
          <div>
            <font-awesome-icon :icon="['far', 'comment']" class="icon" style="color: #A3A3AC;" />
            <span class="label">{{card.number_of_comments}}</span>
          </div>
        </div>
        <div>
          <img />
          <span></span>
        </div>
      </div>
    </div>
    <div class="pay-card">
      <span class="price">{{formatPrice(card.price_for_card, {minimumFractionDigits : 0})}} ₽</span>
      <button typ="button" class="btn violet" @click="buy">Купить карточку</button>
    </div>
    <div class="grade">
      <span class="grade-title"
        >Экспертная оценка XWAY по достижению аналогичного уровня карточки</span
      >
      <div class="grade-row">
        <div>
          <span class="label">Затраты:</span>
          <span class="text">{{formatPrice(card.price_for_top_up, {minimumFractionDigits : 0})}} ₽</span>
        </div>
        <div>
          <span class="label">Сроки:</span>
          <span class="text">{{card.months_for_top_up}} мес</span>
        </div>
        <div>
          <button type="button" class="btn violet-transparent" @click="orderPumping">Заказать прокачку</button>
        </div>
      </div>
    </div>
    <send-request-modal :text="modalText" :adding-text="addingModalText" :show-modal="showModal" @send="sendForm" @click-outside="clickOutside" />
    <success-modal :show-modal="showModalSuccess" @close="closeModal" @click-outside="clickOutside" />
  </div>
</template>

<script>
import SendRequestModal from "../../Modals/SendRequestModal";
import SuccessModal from "../../Modals/SuccessModal";
import formatPrice from '../../../utils/price-formatter'

export default {
  name: "CardList",
  components: {SuccessModal, SendRequestModal},
  props: {
    card: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      formatPrice,
      showModal: false,
      showModalSuccess: false,
      modalText: '',
      addingModalText: ''
    };
  },
  methods: {
    orderPumping() {
      this.showModal = true
      this.modalText = 'Пожалуйста, отправьте свои карточки на расчет, наш менеджер свяжется с вами и проконсультирует.'
      this.addingModalText = 'Если вы пока не знаете, какие карточки хотите продать или карточки находятся оффлайн, оставьте только телефон для связи.'
    },
    buy() {
      this.showModal = true
      this.modalText = 'Пожалуйста, отправьте свои данные и наш менеджер свяжется с вами и проконсультирует.'
    },
    sendForm(formData) {
      console.log('formData', formData)
      this.showModal = false
      this.showModalSuccess = true
      this.modalText = ''
      this.addingModalText = ''
    },
    clickOutside() {
      if (this.showModal) {
        this.showModal = false;
        this.modalText = '';
        this.addingModalText = '';
      }
      if (this.showModalSuccess) {
        this.closeModal();
      }
    },
    closeModal() {
      this.showModalSuccess = false
    }
  }
};
</script>

<style scoped>
a {
  color: #000;
}
.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e5e5e9;
  border-radius: 8px;
  width: calc(50% - 52px);
  text-align: left;
  padding: 20px;
  margin-bottom: 20px;
}

.card-info {
  height: 100%;
  border-bottom: 1px solid #e5e5e9;
  margin-bottom: 20px;
}

.card:hover {
  box-shadow: 0px 6px 16px #0502371f;
}

.label {
  font-size: 12px;
  color: #7b7b85;
  margin-right: 5px;
}

.card-info .text {
  font-size: 12px;
  color: #3c3c47;
  font-weight: 700;
}

.image-block {
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
}

.image-block img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.pay-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.price {
  font-size: 19px;
  font-weight: 700;
}

.grade {
  background-color: #eefff6;
  padding: 12px;
  margin-top: 20px;
}

.grade-title {
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.grade-row {
  display: grid;
  grid-template-columns: 1fr 1fr 138px;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.grade-row span {
  display: block;
}

.grade-row span.text {
  font-size: 14px;
  color: #3c3c47;
  font-weight: 700;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .card {
    width: calc(100% - 42px);
  }
}

.feedback-comments {
  display: flex;
  gap: 10px;
}

.discount-percent {
    color: #F61B58;
}

.discount {
  margin-bottom: 8px;
}

.brand {
  margin-top: 8px;
}

.text-sku {
  font-size: 12px;
  font-weight: 400;
  color: #5B40FF;
  text-decoration: none;
}

.icon {
  margin-right: 5px;
}
</style>
