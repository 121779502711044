<template>
  <Home msg="Welcome to Your Vue.js App" />
</template>

<script>
import Home from "./components/Home.vue";
import "./assets/css/style.css";

export default {
  name: "App",
  components: {
    Home,
  },
};
</script>

<style>
body {
  margin: 0px;
}
#app {
  /* font-family: "Gotham Pro",  Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right; */
  color: #2c3e50;
}
</style>
