<template>
  <div>
    <catalog-header :countCards="cards.length" :countCategories="categories.length" :max="maxPrice" :min="minPrice"/>
    <catalog-content :data="cards" :categories="categories" :brands="brands" />
    <catalog-footer />
  </div>
</template>

<script>
import CatalogHeader from "@/components/Catalog/CatalogHeader";
import CatalogContent from "./Catalog/CatalogContent";
import CatalogFooter from "./Catalog/CatalogFooter";
import Papa from 'papaparse';

export default {
  name: "Home-Page",
  components: {
    CatalogHeader,
    CatalogContent,
    CatalogFooter,
  },
  data() {
    return {
      cards: [],
      categories: [],
      brands: [],
      maxPrice: 0,
      minPrice: 0,
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      Papa.parse('cards_export.csv', {
        download: true,
        complete: results => {
          const keys = results.data[0];
          const cards = results.data.slice(1);
          cards.forEach(card => {
            if (keys.length === card.length) {
              const currentCard = this.fillCard(keys, card);
              if (!this.categories.includes(currentCard.category)) {
                this.categories.push(currentCard.category)
              }
              if (!this.brands.includes(currentCard.brand)) {
                this.brands.push(currentCard.brand)
              }
              this.cards.push(currentCard);
            }
          });
          const prices = this.cards.map(card => Number(card.price_for_card));
          this.maxPrice = Math.max(...prices)
          this.minPrice = Math.min(...prices)
        }
      });
    },
    fillCard(keys, datas) {
      const card = {};
      datas.forEach((data, index) => {
        card[keys[index]] = data;
      })
      return card;
    },
  }
};
</script>
