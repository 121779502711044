<template>
  <div class="content-column">
    <div class="content-header">
      <div class="sorting-block">
        <span>Найдено <b>{{ countCard }}</b> результата</span>
        <div class="button">
          <fwb-dropdown text="Bottom">
            <template #trigger>
              <span :class="currentSorting ? 'purple' : ''">
                <font-awesome-icon :icon="['fas', arrowType]" style="color: #5B40FF;" />
                {{currentSorting ? SortingTypes[currentSorting] : 'По имени'}}
              </span>
            </template>
            <fwb-list-group class="list">
              <fwb-list-group-item v-for="item in dropdown_items" :key="item" @click="() => chooseItem(item)">
                {{SortingTypes[item]}}
                <font-awesome-icon v-if="item === currentSorting" :icon="['fas', 'check']" style="color: #5B40FF;" />
              </fwb-list-group-item>
            </fwb-list-group>
          </fwb-dropdown>

          </div>
      </div>
      <div class="filter-block">
        <button type="button" class="btn violet-transparent" @click="$emit('toggle-fitler')" @touchend="$emit('toggle-fitler')">
          <font-awesome-icon :icon="['fas', 'filter']" style="color: #5B40FF;" />
          Фильтры
        </button>
      </div>
    </div>
    <div class="list">
      <card-list v-for="card in currentCardsList" :key="card.id" :card="card" />
    </div>
    <button typ="button" class="btn green fixed" @click="wantToSell">ПРОДАТЬ СВОЮ КАРТОЧКУ</button>
    <div class="pagination">
      <x-pagination :data="pagination" :countCard="countCard" @change="changePage"/>
    </div>
  </div>
</template>

<script>
import CardList from "./CardList.vue";
import XPagination from "../../XPagination/XPagination";
import { FwbDropdown, FwbListGroup, FwbListGroupItem } from 'flowbite-vue'
import SortingTypes from"../../../constants/SortingTypes"

export default {
  name: "App",
  components: {
    XPagination,
    CardList,
    FwbDropdown,
    FwbListGroup,
    FwbListGroupItem
  },
  props: {
    cards: {
      type: Array,
      default: () => []
    },
    filteringCards: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      SortingTypes,
      countCard: 0,
      pagination: {},
      currentCardsList: [],
      dropdown_items: [],
      currentSorting: 'by_price',
      cardListForSorting: [],
      arrowType: 'arrow-down-wide-short',
      currentCount: 10,
      currentPage: 1,
    };
  },
  watch: {
    filteringCards: {
      deep: true,
      immediate: true,
      handler() {
        this.pagination = {
          totalPages: Math.ceil(this.filteringCards.length/10)
        }
        this.cardListForSorting = [...this.filteringCards];
        this.currentCardsList = this.filteringCards.slice(0, 10);
        this.countCard = this.filteringCards.length;
        this.sortingByType(this.arrowType);
      },
    },
  },
  mounted() {
    this.dropdown_items = ['by_name', 'by_price', 'by_avg_num_of_sales', 'by_avg_convertion', 'by_feedback'];
  },
  methods: {
    changePage(page, count) {
      this.currentCount = count ?? 10;
      this.currentPage = page;
      this.currentCardsList = this.cardListForSorting.slice(this.currentPage * this.currentCount - this.currentCount, this.currentPage * this.currentCount)
      this.pagination = {
        totalPages: Math.ceil(this.cardListForSorting.length/this.currentCount)
      }
    },
    sortingDownItems(prop, type) {
      this.cardListForSorting = this.cardListForSorting.sort((a, b) => {
        let defA = a[prop];
        let defB = b[prop];
        if (type === 'number') {
          defA = Number(a[prop]);
          defB = Number(b[prop]);
        } else if (type === 'array') {
          const arrayA = a[prop].split(' ').filter(_ => !isNaN(parseInt(_)));
          const arrayB = b[prop].split(' ').filter(_ => !isNaN(parseInt(_)));
          defA = arrayA[0];
          defB = arrayB[0];
        }
        if (defA > defB) return 1;
        if (defA == defB) return 0;
        if (defA < defB) return -1;
      });
    },
    sortingUpItems(prop, type) {
      this.cardListForSorting = this.cardListForSorting.sort((a, b) => {
        let defA = a[prop];
        let defB = b[prop];
        if (type === 'number') {
          defA = Number(a[prop]);
          defB = Number(b[prop]);
        } else if (type === 'array') {
          const arrayA = a[prop].split(' ').filter(_ => !isNaN(parseInt(_)));
          const arrayB = b[prop].split(' ').filter(_ => !isNaN(parseInt(_)));
          defA = arrayA[0];
          defB = arrayB[0];
        }
        if (defA < defB) return 1;
        if (defA == defB) return 0;
        if (defA > defB) return -1;
      });
    },
    sortingByType(type) {
      const funcName = type === 'arrow-down-wide-short' ? 'sortingUpItems' : 'sortingDownItems'
      switch (this.currentSorting) {
        case 'by_name':
          this[funcName]('title', 'string');
          this.changePage(this.currentPage, this.currentCount);
          break;
        case 'by_price':
          this[funcName]('price_for_card', 'number');
          this.changePage(this.currentPage, this.currentCount);
          break;
        case 'by_avg_num_of_sales':
          this[funcName]('avg_num_of_sales', 'number');
          this.changePage(this.currentPage, this.currentCount);
          break;
        case 'by_avg_convertion':
          this[funcName]('avg_conv', 'array');
          this.changePage(this.currentPage, this.currentCount);
          break;
        case 'by_feedback':
          this[funcName]('number_of_comments', 'number');
          this.changePage(this.currentPage, this.currentCount);
          break;
        default:
          this.cardListForSorting = [...this.filteringCards];
          this.changePage(this.currentPage, this.currentCount);
      }
    },
    chooseItem(item) {
      if (this.currentSorting === item) {
        if (this.arrowType === 'arrow-down-wide-short') {
          this.arrowType = 'arrow-up-short-wide'
        } else if (this.arrowType === 'arrow-up-short-wide') {
          this.arrowType = 'arrow-down-wide-short'
        }
        this.sortingByType(this.arrowType);
      } else {
        this.currentSorting = item;
        this.arrowType = 'arrow-down-wide-short';
        this.sortingByType(this.arrowType);
      }
    },
    wantToSell() {
      window.location.href = 'https://xway.ru/cardexchange/seller/';
    }
  }
};
</script>

<style scoped>
.content-column {
  display: flex;
  flex-direction: column;
  padding: 0 30px;
}
.list {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.filter-block {
  display: none;
}

.content-header {
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}

.sorting-block {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sorting-block > span {
  text-align: left;
  font-size: 14px;
}

.sorting-block .button {
  display: flex;
  gap: 4px;
  align-items: center;
}

.sorting-block .button span {
  font-size: 14px;
  font-weight: 600;
}

.sorting-block .button .purple {
  color: #5B40FF
}

.sorting-block .list {
  position: absolute;
  right: 70px;
  width: 200px;
  background-color: #ffffff;
  border-radius: 4px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1px;
  box-shadow: 0px 6px 16px 0px #0502371F;
}

.sorting-block .list li {
  list-style-type: none;
  padding: 8px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}

.sorting-block .list li:hover {
  background-color: #F6F6FE;
  border-radius: 4px;
}

.pagination {
  margin-top: auto;
}

.fixed {
  align-self: flex-end;
  position: sticky;
  right: 10px;
  bottom: 20px;
  font-size: 14px;
  padding: 12px 24px;
  box-shadow: 0px 16px 16px 0px #0502371F;
}

@media (max-width: 768px) {
    .filter-block {
        display: flex;
        justify-content: flex-end;
    }
    .sorting-block {
        flex-direction: column;
        align-items: flex-start;
    }
    .content-column {
      padding: 0;
      width: 100%;
    }

  .sorting-block .list {
    right: 0;
    left: 10px;
  }
}
</style>
