<template>
  <ul
    v-if="hasData"
    class="x-pagination"
  >
    <li
      v-if="hasItemsPerPage"
      class="x-pagination__item x-pagination__item_content_first"
    >
      <Multiselect
        v-model="count"
        :options="itemsPerPageOptions"
        :canClear="false"
        @select="selectCount"
      />
    </li>

    <li class="x-pagination__item_content_prev">
      <button type="button" class="btn transparent x-pagination__item_content_prev_last" @click="showFirst">|&lt;</button>
      <button type="button" class="btn transparent" @click="showPrev">&lt;</button>
    </li>

    <li
      v-if="hasFirst()"
      class="x-pagination__item"
    >
      <button type="button" :class="`btn${current === 1 ? ' violet': ' transparent'}`" @click="clickHandler(1)">
        1
      </button>
    </li>

    <li
      v-if="hasFirst() && rangeStart !== 2"
      class="x-pagination__item_delimiter"
    >
      <button type="button" class="btn transparent no-active">&hellip;</button>
    </li>

    <li
      v-for="page in pages"
      :key="page"
      class="x-pagination__item"
    >
      <button type="button" :class="`btn${current === page ? ' violet': ' transparent'}`" @click="clickHandler(page)">
        {{ page }}
      </button>
    </li>

    <li
      v-if="hasLast() && rangeEnd !== totalPages - 1"
      class="x-pagination__item_delimiter"
    >
      <button type="button" class="btn transparent">&hellip;</button>
    </li>

    <li class="x-pagination__item_content_next">
      <button type="button" class="btn transparent" @click="showNext">&gt;</button>
      <button type="button" class="btn transparent x-pagination__item_content_next_last" @click="showLast">&gt;|</button>
    </li>

    <li class="x-pagination__item_content_last">
      <p>
        {{current === 1 ? 1 : count*(current - 1) + 1}} - {{count * current > countCard ? countCard : count * current}} из {{countCard}}
      </p>
    </li>
  </ul>
</template>

<script>
import { defaultItemsPerPageOptions, defaultItemPerPageSelected } from './shared';
import Multiselect from '@vueform/multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    countCard: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    pageRange: {
      type: Number,
      default: 1,
    },
    pageCount: {
      type: Number,
      default: 5,
    },
    itemsPerPageOptions: {
      type: Array,
      default: () => defaultItemsPerPageOptions(),
    },
    itemsPerPage: {
      type: Object,
      default: () => defaultItemPerPageSelected(),
    },
    hasItemsPerPage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selfItemsPerPage: this.itemsPerPage,
      current: this.currentPage,
      page: '',
      count: this.itemsPerPageOptions[0]
    };
  },
  watch: {
    currentPage(value) {
      this.current = value;
    },
    itemsPerPage(value) {
      this.selfItemsPerPage = value;
    },
  },
  computed: {
    hasData() {
      return !!Object.keys(this.data).length;
    },
    pages() {
      let pages = [];

      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i);
      }

      return pages;
    },
    totalPages() {
      return this.data.totalPages;
    },
    rangeStart() {
      const start = this.current - this.pageRange;
      return start > 0 ? start : 1;
    },
    rangeEnd() {
      const end = this.current + this.pageCount - 1;

      return end <= this.totalPages ? end : this.totalPages;
    },
  },
  methods: {
    hasFirst() {
      return this.rangeStart !== 1;
    },
    hasLast() {
      return this.rangeEnd < this.totalPages;
    },
    showPrev() {
      this.current = this.current - this.pageRange > 0 ? this.current - this.pageRange : 1;
      this.$emit('change', this.current);
    },
    showNext() {
      this.current = this.current + this.pageRange < this.totalPages
        ? this.current + this.pageRange
        : this.totalPages;
      this.$emit('change', this.current);
    },
    clickHandler(page) {
      this.current = page;
      this.$emit('change', page);
    },
    showFirst() {
      this.current = 1;
      this.$emit('change', this.current);
    },
    showLast() {
      this.current = this.totalPages;
      this.$emit('change', this.current);
    },
    selectCount() {
      this.current = 1;
      this.$emit('change', this.current, this.count);
    },
    setPage() {
      let page = parseInt(this.page, 10);
      if (page && page > this.totalPages) return;
      this.current = page || 1;

      this.clickHandler(this.current);
    },
    resetPagination() {
      this.current = 1;
      this.clickHandler(this.current);
    },
  },
};
</script>

<style scoped>
  .x-pagination {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .x-pagination__item_content_first {
    margin-right: 10px;
  }

  .x-pagination__item_content_first .multiselect {
    width: 115px;
  }

  .multiselect-caret {
    margin: 0;
  }

  .x-pagination__item_content_first .multiselect-single-label {
    padding: 0!important;
  }

  .x-pagination__item_content_last {
    color: #3c3c47;
    font-size: 14px;
    margin-left: auto;
  }

  .x-pagination__item_content_prev,
  .x-pagination__item_content_next {
    white-space: nowrap;
  }

  .pagination__select {
    border: 1px solid #cacad3;
  }

  .btn {
    padding: 8px 12px;
  }

  .btn.transparent.no-active:hover {
    cursor: initial;
  }

  @media (max-width: 768px) {
    .x-pagination__item_content_next,
    .x-pagination__item_content_prev {
      display: none;
    }

    .x-pagination {
      margin-bottom: 10px;
    }
  }
</style>
