<template>
  <div class="catalog-header">
    <p
      v-if="showContent"
      class="filters-column_title"
    >
      ВЫБЕРИТЕ КАРТОЧКУ ТОВАРА ДЛЯ ПОКУПКИ
    </p>
    <div class="sorting-block" />
  </div>
  <div class="catalog-body">
    <filter-block
      v-if="showFilter"
      class="filters col"
      :current-categories="categories"
      :current-brands="brands"
      @reset="resetFilter"
      @filtering="filtering"
      @toggle-fitler="toggleFilter"
    />
    <content-block
      v-if="showContent"
      class="content col"
      :cards="data"
      :filteringCards="filteringCardsList"
      @toggle-fitler="toggleFilter"
    />
  </div>
</template>

<script>
import FilterBlock from "./Content/FilterBlock";
import ContentBlock from "./Content/ContentBlock";

export default {
  name: "App",
  components: {
    FilterBlock,
    ContentBlock
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    categories: {
      type: Array,
      default: () => []
    },
    brands: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      handler() {
        this.filteringCardsList = [...this.data];
      },
    }
  },
  data() {
    return {
      filteringCardsList: [],
      showFilter: false,
      showContent: true,
    };
  },

  mounted () {
    const widthWindow = window.innerWidth;
    this.showFilter = widthWindow > 768;
  },

  created () {
    window.addEventListener('resize', this.updateShowFilter)
  },

  methods: {
    updateShowFilter() {
      const widthWindow = window.innerWidth;
      this.showFilter = widthWindow > 768;
      this.showContent = widthWindow > 768 || !this.showFilter;
    },
    toggleFilter() {
      this.showFilter = !this.showFilter;
      this.showContent = !this.showContent;
    },
    resetFilter() {
      this.filteringCardsList = [...this.data];
      const widthWindow = window.innerWidth;
      if (widthWindow <= 768) {
        this.toggleFilter()
      }
    },
    filtering(filter) {
      Object.keys(filter).forEach(key => {
        if (filter[key] !== null) {
          this.filteringCardsList = this.data.filter(card => {
            if (Array.isArray(filter[key])) {
              if (filter[key][0] && filter[key][1]) {
                return Number(filter[key][0]) <= Number(card[key]) && Number(filter[key][1]) >= Number(card[key])
              } else if (filter[key][0] && !filter[key][1]) {
                return Number(filter[key][0]) <= Number(card[key])
              } else if (!filter[key][0] && filter[key][1]) {
                return Number(filter[key][1]) >= Number(card[key])
              }
            }
            return filter[key] ? card[key] === filter[key] || filter[key].includes(card[key]) : true;
          })
        }
      })
      const widthWindow = window.innerWidth;
      if (widthWindow <= 768) {
        this.toggleFilter()
      }
    }
  }
};
</script>

<style scoped>
.catalog-header {
  display: grid;
  grid-template-columns: 1fr 3fr;
  padding: 60px 60px 0 60px;
}
.catalog-body {
  padding: 0 60px 60px 60px;
  display: grid;
  grid-template-columns: 1fr 3fr;
}
.filters.col {
  display: block;
}

.filters-column_title {
  font-weight: 700;
  margin-top: 0;
}

.sorting-block {
  height: 0px;
}

.content.col {
  margin-top: -65px;
}

@media (max-width: 768px) {
  .catalog-header,
  .catalog-body {
    padding: 10px;
    display: flex;
    width: calc(100% - 20px);
  }
  .filters.col {
    position: absolute;
    background-color: #ffffff;
    width: calc(100% - 30px);
    padding: 15px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .filters-column_title {
    margin-top: 30px;
    margin-bottom: 50px;
  }
}
@media (min-width: 768px) {
  .filters.col {
    display: block;
  }
}
</style>
